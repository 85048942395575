

.hamburger-icon {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-items {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #333;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.menu-items ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-items ul li {
    padding: 10px;
    border-bottom: 1px solid #444;
}

.menu-items ul li:last-child {
    border-bottom: none;
}

.menu-items ul li a,
.menu-items ul li button {
    color: white;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.menu-items ul li button:hover,
.menu-items ul li a:hover {
    color: #FFD700;
}