.icon-image {
    width: 48px; /* 画像の幅を設定 */
    height: 48px; /* 画像の高さを設定 */
    margin-right: 8px; /* テキストとの間隔を設定 */
}

.icon-small {
    width: 16px; /* 必要に応じてサイズを調整 */
    height: 16px;
    margin-right: 8px; /* テキストとの間隔を調整 */
    vertical-align: middle; /* 文字とアイコンを中央揃え */
}

.link-style {
    background-color: #333;
    padding: 1rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.link-style:hover {
    background-color: #444;
}